import gql from 'graphql-tag';

const query = {
  patientMe: gql`
    query patientMe {
      patientMe {
        code
        success
        message
        patient {
          id
          email
          timezone
          contact_no
          secondary_contact_no
          profile {
            patient_id
            first_name
            middle_name
            last_name
            pronoun
            gender
            date_of_birth
            street
            city
            state
            postcode
            country
            medicare_no
            profile_pic
            is_australian_resident
            is_over_18
            tried_conventional_treatment
            medical_symptoms
            medical_conditions
            conditions
            other_conditions
            how_long_suffered
            using_any_medicine
            have_any_allergy
            relevant_notes
            onboarding_step
            about_polln
          }
          phone_verification
          email_verification
          status
          billing_address {
            street
            city
            state
            postcode
          }
          delivery_address {
            street
            city
            state
            postcode
          }
          consultation_status
          last_consultation_date
          consent_approved
          same_delivery
          same_billing
        }
      }
    }
  `,
  doctorMe: gql`
    query doctorMe {
      doctorMe {
        code
        success
        message
        doctor {
          id
          email
          timezone
          contact_no
          profile {
            doctor_id
            first_name
            last_name
            date_of_birth
            street
            city
            state
            postcode
            country
            profile_pic
            description
            provider_no
            prescriber_no
            onboarding_step
          }
          phone_verification
          email_verification
          status
        }
      }
    }
  `,
  consultationDetails: gql`
    query consultationDetails($id: String!) {
      consultationDetails(id: $id) {
        code
        success
        message
        consultation {
          id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              profile_pic
            }
          }
          patient {
            id
            email
            timezone
            contact_no
            profile {
              patient_id
              first_name
              middle_name
              last_name
              pronoun
              gender
              date_of_birth
              street
              city
              state
              postcode
              country
              medicare_no
              profile_pic
              is_australian_resident
              is_over_18
              tried_conventional_treatment
              medical_symptoms
              medical_conditions
              conditions
              other_conditions
              how_long_suffered
              using_any_medicine
              have_any_allergy
              relevant_notes
              onboarding_step
              about_polln
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            price
          }
          enquiry
          step
        }
      }
    }
  `,
  patientPrescription: gql`
    query patientPrescription($id: String!) {
      patientPrescription(id: $id) {
        code
        success
        message
        prescription {
          id
          patient_id
          consultation_id
          products {
            prescription_id
            product_id
            no_of_units
            repeats
            dosage
          }
          custom_products {
            product_name
            product_details
            product_type
            no_of_units
            repeats
            dosage
            brand_name
            sku
            type
            format
          }
          consultation_notes
          treatment_notes
          careteam_notes
        }
      }
    }
  `,
  patientPreviousPrescription: gql`
    query patientPreviousPrescription($patient_id: String!, $consultation_id: String!) {
      patientPreviousPrescription(patient_id: $patient_id, consultation_id: $consultation_id) {
        code
        message
        success
        prescription {
          id
          patient_id
          consultation_id
          products {
            prescription_id
            product_id
            product {
              id
              type
              brand
              product_name
              stock
              format
              genetics
              spectrum
              cannabinoid
              carrier_oil
              schedule
              price
              is_active
            }
            no_of_units
            repeats
            dosage
          }
          custom_products {
            product_name
            product_details
            product_type
            no_of_units
            repeats
            dosage
            brand_name
            sku
            type
            format
          }
          consultation_notes
          treatment_notes
          careteam_notes
          consultation {
            id
            patient {
              id
              email
              timezone
              contact_no
              profile {
                patient_id
                first_name
                middle_name
                last_name
                pronoun
                gender
                date_of_birth
                street
                city
                state
                postcode
                country
                medicare_no
                profile_pic
                is_australian_resident
                is_over_18
                tried_conventional_treatment
                medical_symptoms
                medical_conditions
                conditions
                other_conditions
                how_long_suffered
                using_any_medicine
                have_any_allergy
                relevant_notes
                onboarding_step
                about_polln
              }
            }
            doctor {
              id
              email
              timezone
              contact_no
              profile {
                doctor_id
                first_name
                last_name
                date_of_birth
                street
                city
                state
                postcode
                country
                profile_pic
                description
                provider_no
                prescriber_no
                onboarding_step
              }
              phone_verification
              email_verification
              status
            }
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
        }
      }
    }
  `,
  products: gql`
    query products {
      products {
        code
        success
        message
        products {
          id
          type
          brand
          product_name
          stock
          format
          genetics
          spectrum
          cannabinoid
          carrier_oil
          schedule
          price
          is_active
        }
      }
    }
  `
};

export default query;
