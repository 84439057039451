import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import query from 'graphql/query';
import { IMePatientResponse, IMeDoctorResponse, IUserPatient, IUserDoctor } from 'utils/models';
import storage from 'utils/storage';

interface IContext {
  userPatient: IUserPatient | null;
  userDoctor: IUserDoctor | null;
  isLoggedIn: boolean;
  isOnBoarding: boolean;
  isLoading: boolean;
  token: string | null;
  logout: () => void;
  getPatient: () => Promise<any> | void;
  getDoctor: () => Promise<any> | void;
}

const AuthContext = createContext<IContext>({
  userPatient: null,
  userDoctor: null,
  isLoggedIn: false,
  isOnBoarding: false,
  isLoading: true,
  token: null,
  logout: () => {},
  getPatient: () => {},
  getDoctor: () => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userPatient, setUserPatient] = useState<IUserPatient | null>(null);
  const [userDoctor, setUserDoctor] = useState<IUserDoctor | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isOnBoarding, setIsOnBoarding] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * patient me query
   */
  const [patientMe, { data: patientMeData, loading: patientMeLoading }] = useLazyQuery<{
    patientMe: IMePatientResponse;
  }>(query.patientMe, {
    fetchPolicy: 'network-only'
  });

  /**
   * doctor me query
   */
  const [doctorMe, { data: doctorMeData, loading: doctorMeLoading }] = useLazyQuery<{
    doctorMe: IMeDoctorResponse;
  }>(query.doctorMe, {
    fetchPolicy: 'network-only'
  });

  const handleAuthPatient = useCallback((meToken: string | null, me: IUserPatient) => {
    if (meToken && me) {
      storage.setItem('token', meToken);
      setToken(meToken);
      setUserPatient(me);
    }
  }, []);

  const handleAuthDoctor = useCallback((meToken: string | null, me: IUserDoctor) => {
    if (meToken && me) {
      storage.setItem('token', meToken);
      setToken(meToken);
      setUserDoctor(me);
    }
  }, []);

  const logout = useCallback(() => {
    storage.removeItem('token');
    setUserPatient(null);
    setIsLoggedIn(false);
    setIsOnBoarding(false);
    setToken(null);
  }, []);

  const getPatient = useCallback(async () => {
    try {
      await patientMe();
    } catch (error) {
      logout();
    }
  }, [patientMe, logout]);

  const getDoctor = useCallback(async () => {
    try {
      await doctorMe();
    } catch (error) {
      logout();
    }
  }, [doctorMe, logout]);

  useEffect(() => {
    if (patientMeData && patientMeData?.patientMe?.code === '200' && patientMeData?.patientMe?.success) {
      const me = patientMeData.patientMe.patient;
      handleAuthPatient(storage.getItem('token'), me);
    }

    if (patientMeData && patientMeData?.patientMe?.code === '422' && !patientMeData?.patientMe?.success) {
      logout();
    }
  }, [patientMeData, token, handleAuthPatient, logout]);

  useEffect(() => {
    // console.log(doctorMeData);
    if (doctorMeData && doctorMeData?.doctorMe?.code === '200' && doctorMeData?.doctorMe?.success) {
      const me = doctorMeData.doctorMe.doctor;
      handleAuthDoctor(storage.getItem('token'), me);
    }

    if (doctorMeData && doctorMeData?.doctorMe?.code === '422' && !doctorMeData?.doctorMe?.success) {
      logout();
    }
  }, [doctorMeData, token, handleAuthDoctor, logout]);

  // useEffect(() => {
  //   if (token) getDoctor();
  // }, [getDoctor, token]);

  useEffect(() => {
    setIsLoading(patientMeLoading || doctorMeLoading);
  }, [patientMeLoading, doctorMeLoading]);

  useEffect(() => {
    if (storage.getItem('token')) setToken(storage.getItem('token'));
  }, []);

  return (
    <AuthContext.Provider value={{ logout, getPatient, getDoctor, userPatient, userDoctor, isLoggedIn, isOnBoarding, isLoading, token }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
