import React, { FC, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Loadable from 'components/Loadable';

const Patient = Loadable(lazy(() => import('pages/authenticated/Patient')));
const Doctor = Loadable(lazy(() => import('pages/authenticated/Doctor')));
const MeetingRoom = Loadable(lazy(() => import('pages/authenticated/MeetingRoom')));

const Router: FC = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Routes>
          <Route path="patient">
            <Route path="waiting-room/:token/:id" element={<Patient />} />
            <Route path="meeting-room/:id" element={<MeetingRoom agent="patient" />} />
          </Route>

          <Route path="doctor">
            <Route path="waiting-room/:token/:id" element={<Doctor />} />
            <Route path="meeting-room/:id" element={<MeetingRoom agent="doctor" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
