import React, { ReactNode, Suspense, LazyExoticComponent, ComponentType } from 'react';
import LoaderSpinner from './LoaderSpinner';

const Loadable = (Component: LazyExoticComponent<() => JSX.Element> | ComponentType<ReactNode> | any) => (props: any) => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center fixed h-full w-full">
          <LoaderSpinner className="w-10 h-20" />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
